<template>
  <div class="my-order">
    <van-nav-bar
      left-arrow
      left-text="返回"
      title="我的订单"
      @click-left="onLeft"
      fixed
      placeholder
      z-index="100"
    />
    <van-tabs
      @click="onTabChange"
      animated
      v-model="tabType"
      title-active-color="#4587FF"
      swipeable
    >
      <van-tab
        v-for="(item, tabIndex) in orderTab"
        :title="item.name"
        :key="tabIndex"
      >
        <van-pull-refresh
          v-model="isRefresh"
          success-text="刷新成功"
          @refresh="onRefresh"
        >
          <div
            :class="[
              'order-wrap',
              !orderLoad && showImg(tabType) ? 'order-wrap-default' : '',
            ]"
            v-if="tabIndex === tabType"
            :style="{
              // height: this.orderData.length < 2 ? 'calc(100vh - 111px)' : '',
            }"
          >
            <div class="order-wrap-def" v-if="!orderLoad && showImg(tabType)">
              <span>暂无订单</span>
            </div>
            <template v-for="(orders, orders_index) in orderData">
              <div class="order-wrap-item" :key="orders_index"
                v-if="orders.childProduct.length
                && (tabType==1?orders.payStatus==1&&!orders.evaluateStatus:1)
                && (tabType==2?orders.payStatus==2&&!orders.evaluateStatus:1)
                && (tabType==3?orders.payStatus==2&&orders.evaluateStatus:1)
                || (tabType==3?orders.payStatus==2&&orders.memberOrder:0)" >
                <div class="order-wrap-item-top">
                  <div class="order-wrap-item-top-number">
                    <span class="txt">订单编号：</span>
                    <span class="number">{{ orders.orderId }}</span>
                  </div>
                  <div class="order-wrap-item-top-lab">
                    <span v-if="orders.payStatus === 1">待付款</span>
                    <span v-if="orders.payStatus == 2 && !orders.evaluateStatus && !orders.memberOrder">待评价</span>
                    <span v-if="(orders.payStatus == 2 && orders.evaluateStatus) || (orders.payStatus == 2 && orders.memberOrder)">已完成</span>
                  </div>
                </div>
                <div class="order-wrap-item-center"   @click="clickLookproduct(orders)">
                  <order-item
                    v-for="(pro, index) in orders.childProduct"
                    :product="pro"
                    :key="index"
                  />
                </div>
                <div class="order-wrap-item-statis">
                  <div class="total">
                    <span class="txt">共<span class="quantity">{{getProductNum(orders.childProduct)}}</span>件商品</span>
                    <span class="price">合计:<span>￥{{ orders.price }}</span></span>
                  </div>
                  <div class="btns">
                    <div class="btns-cancel" v-if="orders.payStatus == 1" @click="cancelOrderSure(orders.orderId)">取消订单</div>
                    <div class="btns-payfor" v-if="orders.payStatus == 1" @click="pay(orders.orderId,orders)">立即付款</div>
                    <div class="btns-evaluate" v-if="orders.payStatus == 2 && !orders.evaluateStatus && !orders.memberOrder" @click="evaluate(orders.orderId)">评价</div>
                    <div class="btns-see" v-if="(orders.payStatus == 2 && orders.evaluateStatus) || (orders.payStatus == 2&&orders.memberOrder)" @click="openOrder(orders.orderId)">查看订单</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderItem from "./OrderItem.vue";
import { Dialog } from 'vant';
import {
  getOrderInfo,deleteOrderById
} from "@api/user.js";
import qs from "qs"
export default {
  data() {
    return {
      //标签索引
      tabType: this.$route.query.tabId,
      //标签内容
      orderTab: [
        {
          name: "全部订单",
        },
        {
          name: "待付款",
        },
        {
          name: "待评价",
        },
        {
          name: "已完成",
        },
      ],
      //是否刷新
      isRefresh: false,
      //加载完成
      orderLoad: false,
      //订单数据
      orderData: [],
      isshow:false
    };
  },
  components: {
    OrderItem,
  },
  mounted(){
     window.onLeft = this.onLeft
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    })
  },
  methods: {
    showImg(){
      /*if (this.tabType === 3)
      return this.orderData.every(item=>!(item.payStatus === 2 && item.evaluateStatus));
      if (this.tabType === 2)
        return  this.orderData.every(item=>{item.payStatus === 2 && !item.evaluateStatus})
      if (this.tabType === 1)
        return this.orderData.every(item=>{item.payStatus === 1})
      if (this.tabType === 0)
        return false;*/
      // return true;
      return this.orderData.length <= 0;
    },
    //返回
    onLeft() {
      this.$router.push({
        name:'My'
      });
    },
    //刷新一下
    onRefresh() {
      this.orderLoad = false;
      this.isRefresh = false;
      this.orderData = [];
      this.getOrderData(this.tabType);
      const timer = setInterval(()=>{
        if(this.orderLoad){
          this.isRefresh = false;
          clearInterval(timer);
        }
      });
    },

    //查看订单
    clickLookproduct(item){
      if(this.tabType == 3){
        return
      }else if(this.tabType == 1){
        this.$router.push({
          name:'orderitem',
          query:{
            oid:item.orderId,
            payStatus:1,
            tabType:this.tabType
          }
        })
      }else if(this.tabType == 2){
        this.$router.push({
         name:'orderitem',
         query:{
           oid:item.orderId,
           payStatus:2,
           tabType:this.tabType
         }
       })
      }else{
          if(item.payStatus == 1){
            this.$router.push({
              name:'orderitem',
              query:{
                oid:item.orderId,
                payStatus:1,
                tabType:this.tabType
              }
            })
          }else if(item.payStatus == 2 && !item.evaluateStatus && !item.meberOrder){
            this.$router.push({
              name:'orderitem',
              query:{
                oid:item.orderId,
                payStatus:2,
                tabType:this.tabType
              }
            })
          }else if((item.payStatus == 2 && item.evaluateStatus) || (item.payStatus == 2 && item.memberOrder)){
            return
          }
      }
      
    },
    //切换tab
    onTabChange() {
      this.orderLoad = false; //未加载完成状态
      this.getOrderData(this.tabType);
    },
    //获取订单数据
    getOrderData(tab) {
      if(this.isshow == true){

      }else{
         this.$toast.loading({
          forbidClick: true,
          loadingType: "spinner",
        });
      }
      this.orderData = [];
      let params = {
        payStatus: tab
      };
      if (tab === 3) {
        params.payStatus = 2;
      }
      this.findOrderInfo(params)
      .then(res=>{
        if (res.code === 0){
          this.$toast.clear()
          let data = res.data
          this.isshow = false
          this.orderData.push(...data)
          if (tab === 3){
            this.orderData = []
            for (const datum of data) {
              if (datum.payStatus === 2){
                if (datum.memberOrder){
                  this.orderData.push(datum)
                }
                if (datum.evaluateStatus){
                  this.orderData.push(datum)
                }
              }
            }
            console.log(this.orderData)
          }
          if (tab === 2){
            this.orderData = []
            for (const datum of data) {
              if (datum.payStatus === 2 && !datum.evaluateStatus){
                if (datum.memberOrder){
                  continue
                }
                this.orderData.push(datum)
              }
            }
          }
        }
      })
    },
    async findOrderInfo(params){
      return getOrderInfo(JSON.stringify(params))
    },

    //计算商品总数
    getProductNum(products){
      let count = 0;
      products.forEach(product => {
        count += product.productNum;
      });
      return count;
    },

    //打开订单详情
    openOrder(oid){
       this.$router.push({
         name:'orderitem',
         query:{
           oid:oid,
           payStatus:2,
           tabType:this.tabType
         }
       })
    },
    
    //打开评价页
    evaluate(oid){
      this.$router.push({
        name : "orderevaluate",
        params : {
          oid
        }
      })
    },
    
    //取消订单确定
    cancelOrderSure(oid){
       Dialog.confirm({
        title: '提示',
        message: '是否取消订单？',
        confirmButtonColor:'#0f76f2',
      })
        .then(() => {
          this.cancelOrder(oid)
        })
        .catch(() => {
          // on cancel
        });
    },
    //取消订单
    async cancelOrder(oid) {
        const data = {
           orderId:oid
        }
        const res = await deleteOrderById(qs.stringify(data))
        if(res.code === 0 ){
            this.$toast("订单已取消！")
            this.isshow = true
            this.onTabChange()
        }else{
          this.$toast(res.msg)
        }
    },
    
    //支付
    pay(oid,data){
      let uid = this.profile.userId;
      window.location.href = `https://p.writemall.com/order2/wxH5WebProductOrderCallBack?userid=${uid}&oid=${oid}&orderType=${data.orderType}&platform=1`
    }
  },
  created() {
    this.getOrderData(this.tabType);
     window.onLeft = this.onLeft
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__content {
  height: 57px;
}
::v-deep .van-hairline--bottom::after {
  border: none;
}
::v-deep .van-icon {
  color: #333333;
}
::v-deep .van-nav-bar__text {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 3px;
}
::v-deep .van-nav-bar__title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
::v-deep .van-tabs__line {
  background-color: #4587ff;
}
::v-deep .van-tabs.topfix{
  .van-tabs__wrap{
    position:fixed;
    width:100%;
    top:0;
    z-index:100;
    background-color:#fff;
  }
}
::v-deep .topfix{
  position:fixed;
  width:100%;
  top:0;
  z-index:100;
  background-color:#fff;
}
.my-order {
  font-family: PingFang SC;
  .order-wrap {
    // padding-bottom: 30px;
    &-default {
      display: flex;
      justify-content: center;
      height: calc(100vh - 101px);
      padding-top: 104px;
    }
    &-def {
      display: flex;
      flex-direction: column-reverse;
      width: 166px;
      height: 198px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      background: url("~@image/order-default.png") center no-repeat;
      background-size: 166px 122px;
    }
    &-item {
      border-top: 10px solid #f8f8f8;
      padding: 0 20px;
      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 42px;
        border-bottom: 1px solid #e3e3e3;
        &-number {
          display: flex;
          align-items: center;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #2f3135;
          }
        }
        &-lab {
          font-size: 14px;
          font-weight: 500;
          color: #4587ff;
        }
      }
      &-statis {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 62px;
        .total {
          display: flex;
          align-items: center;
          span {
            font-size: 12px;
            font-weight: 400;
            color: #2f3135;
          }
          .txt {
            margin-right: 5px;
          }
          .price > span {
            margin-left: 2px;
            color: #eb4924;
          }
        }
        .btns {
          display: flex;
          align-items: center;
          > div {
            width: 84px;
            height: 42px;
            text-align: center;
            line-height: 42px;
            border-radius: 5px;
          }
          &-cancel {
            border: 1px solid #999999;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
          }
          &-payfor,
          &-evaluate {
            margin-left: 10px;
            background-color: #4587ff;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
          }
          &-see {
            background: #ffffff;
            border: 1px solid #4587ff;
            font-size: 14px;
            font-weight: 500;
            color: #4587ff;
          }
          &-evaluate {
            font-weight: 500;
          }
        }
      }
    }
  }
}
::v-deep .van-tab__pane, .van-tab__pane-wrapper{
  margin-top:23px;
}
::v-deep .van-tabs__wrap{
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
}
</style>